@media (min-width: 0px){
    .App {
        color: aliceblue;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        background-image: url("../public/images/burger-with-neon-sign-that-says-burger-it.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        min-height: 100vh;
            display: flex;
            flex-direction: column;
        }
}
@media (min-width: 900px) {
    .App {
        color: rgb(255, 240, 241);
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        background-image: url("../public/images/burger-with-neon-sign-that-says-burger-it.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        min-height: 100vh;
            display: flex;
            flex-direction: column;
        } 
}
.navbar{
  background: rgba(165, 35, 35, 0.5) !important;    /*  70% opaque green */ 
  color: rgb(255, 255, 255)  !important;
}
.form-control {
  background-color: rgb(196, 192, 192) !important;
}

.form-control:focus {
  background-color: rgb(181, 188, 199) !important;
}
.tbody, td, tfoot, th, thead,tr,table {
  opacity: 93%  !important;
  color: rgb(255, 255, 255)  !important;
  background-color: rgb(0, 0, 0) !important;
}
.list-group-item,list-group {
  opacity: 93%  !important;
  color: rgb(255, 255, 255)  !important;
  background-color: rgb(0, 0, 0) !important;
}
.btn{
  opacity: 93%  !important;
  color: rgb(255, 255, 255)  !important;
}
.container-fluid {
  background: rgba(255, 0, 0, 0.2) !important;
  opacity: 93%  !important;
  color: rgb(255, 255, 255)  !important;
}
.btn:hover{
  filter: brightness(1.5) !important;
}
.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 60px;
    height: 60px;
    margin: -76px 0 0 -76px;
    border: 8px solid #ecc6c6;
    border-radius: 50%;
    border-top: 8px solid #d1b2b2;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 0.5s;
    animation-name: animatebottom;
    animation-duration: 0.5s
  }
  
  @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  
  @keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }
 
