body {
    margin: 0;
    padding: 0;
    font: 12pt "Tahoma";
  }
  
  @page {
    size: 5in auto;
    margin: 0px;
    padding: 0px;
  }
  @media print {
    .page {
      margin: 0 !important;
      padding: 0 !important;
      justify-content: center;
      text-align: center;
      border: initial;
      border-radius: initial;
      width: initial;
      box-shadow: initial;
      background: initial;
    }
    .sub-page {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  